.input-control {
  height: 40px;
  color: #000000 !important;
  border: 1px solid #cccccc !important;
  border-radius: 50px !important;
  padding: 0 20px !important;
  outline: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-div-password {
  height: 0;
}

.input-password {
  font-size: 25px;
  position: relative;
  top: -34px;
  left: -20px;
  color: #cccccc;
  cursor: pointer;
}
