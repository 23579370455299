.patient-bg {
  padding: 24px;
  width: 100%;
  min-height: 100vh;
  background: url("../../../public/img/background/bg-login-back.svg") no-repeat
      left bottom,
    linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
  display: flex;
  flex-direction: column;
}

.patient-container {
  width: 100%;
  flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button-patient-style {
  display:'flex';
  align-items:'center';
  justify-content:'center';
  height:'fit-content';
  max-width: fit-content !important;
}

.button-control-h-default-poli-false {
  height: 120px;
  font-size: 48px !important;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #9f9d9d !important;
  border-radius: 99px !important; /* Menambahkan border-radius 16px */
  background-color: #9f9d9d !important;
}

.kiosk-font-face {
  font-family: 'SF Mono', monospace;
}


.title-hospital-name {
  font-weight: bold !important;
  font-size: xxx-large;
  text-align: center !important;
  margin-bottom: 30px;
  /* display: flex !important;
  justify-content: center !important;
  margin: 0; */
}

.title-hospital-service {
  font-size: xx-large!important;
  text-align: center !important;
  font-weight: 350;
}
