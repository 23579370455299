.custom-dropdown {
    font-size: 35px !important;
    font-weight: bold !important;
    border: 1px solid #00bd84 !important;
    text-align: center;
    position: relative;
    cursor: pointer;
    padding: 10px;
    margin: 0;
    height: 150px;
    width: 100%;
    border-radius: 12px !important; /* Menambahkan border-radius 16px */
    color: #ffffff !important;
    display: flex;
    gap: 10px;
    align-items: center !important;
    background-color: #00bd84 !important;
  }

  .custom-dropdown .select-toggle {
    display: inline-block;
    width: 100%;
  }
  
  .custom-dropdown .options-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    position: absolute;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 16px;
    padding: 10px;
    width: 100%;
    z-index: 1;
    top: 100%
  }

  .custom-dropdown .options-container-doctor {
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
    /* gap: 10px; */
    position: absolute;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 16px;
    padding: 10px;
    width: 100%;
    z-index: 1;
    height: 50vh;
    top: 100%;
    font-size: 28px;
    font-weight: 400
  }
  
  .custom-dropdown .option {
    padding: 10px 20px;
    border: 1px solid transparent;
    font-weight: bold !important;
    font-size: 48px !important;
    border-radius: 16px;
    cursor: pointer;
    color:#00bd84;
    padding:40px
  }
  
  .custom-dropdown .option.selected {
    background-color: #00bd84;
    color: rgb(255, 255, 255) !important;
    border-color: #00bd84;
    font-weight: bold;
    font-size: 48px !important;
    border: 1px;
  
  }
  
  .custom-dropdown .option.disabled {
    background-color: #cccccc; /* Warna abu-abu untuk opsi dinonaktifkan */
    color: #999999 !important; /* Warna teks abu-abu untuk opsi dinonaktifkan */
    cursor: not-allowed; /* Kursor tidak diizinkan untuk opsi dinonaktifkan */
}

 .custom-dropdown .selected {
    color: #00bd84 !important;
  }

  .custom-dropdown .options-container.single-option {
    display: block;
}