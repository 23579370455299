.input-select-control {
  height: 42px;
  color: #000000 !important;
  border: 1px solid #cccccc !important;
  border-radius: 50px !important;
  padding: 1px 40px 0px 10px !important;
  outline: none;
}

.input-div-search {
  height: 0;
}

.input-search {
  font-size: 20px;
  position: relative;
  top: -32px;
  left: -20px;
  color: #cccccc;
  cursor: pointer;
}
