.queue-bg {
  padding: 40px;
  width: 100%;
  min-height: 100vh;
  background: url("../../../../public/img/background/bg-tv.svg") no-repeat left
      bottom,
    linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
}

.header-container {
  width: 100%;
}

.header-title {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 53.64px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 42px;
}

.select {
  position: absolute;
  right: 0;
  top: 0;
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
}

.body-container {
  width: 100%;
  border-radius: 8px !important;
  justify-content: flex-start !important;
  position: absolute;
  top: 0;
}

.table-container {
  max-width: 100%;
  border-radius: 8px !important;
  align-items: center;
  min-height: 250px;
  padding-bottom: 4px;
}

.table-content {
  border-radius: 8px !important;
  width: 100%;
  min-width: 220px;
}

.table-head {
  max-height: 50px;
  height: 50px
}

.table-row-poli {
  padding: 8px;
  gap: 10px;
  opacity: 0px;
  display: grid;
  max-height: 120px;
  min-height: 80px;
  width: 100%;
  border-radius: 8px 8px 0px 0px !important;
  background: #011C70;
}

.last-table-data {
  border-radius: 0px 0px 8px 8px !important;
}

.doctor-name-column {
  overflow: hidden !important;
  text-overflow: ellipsis  !important;
}

.doctor-name {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis  !important;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
  color: #FFFFFF;
  max-height: 35px;
  width: 100%;
}

.poli-name {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #FFFFFF;
}

.empty-queue {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #999999;
  background: #FFFFFF;
  border-radius: 0px 0px 8px 8px !important;
}

.queue {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: black;
  background: #FFFFFF;
  min-width: 100%;
  overflow: hidden !important;
}

.queue-on-consultation {
  padding-right: 50px;
}

.queue-active {
  font-size: 24px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 10px;
  width: 100%;
  margin-top: 120px;
  align-self: flex-start !important;
}

.body-container-table {
  width: 100%;
  display: flex !important;
  justify-content: flex-start !important;
  max-height: 100%;
  overflow: hidden !important;
  padding: 0px 50px !important;
}

.one-row tr:nth-child(n+22) {
  display: none !important;
}

.two-rows tr:nth-child(n+11) {
  display: none !important;
}

.three-rows tr:nth-child(n+7) {
  display: none !important;
}

@media (max-width: 1500px) {
  .queue-bg {
    width: 100%;
    height: 100%;
  }

  .doctor-name {
    font-size: 25px;
  }
  
  .poli-name {
    font-family: Montserrat;
    font-size: 20px;
  }

  .queue-active {
    font-size: 24px !important;
  }

  .empty-queue {
    font-size: 20px;
  }

  .header-title {
    font-size: 30px;
  }
}

@media (max-width: 1000px) {
  .body-container {
    position: relative;
  }

  .header-title {
    padding-top: 54px !important;
  }

  .select {
    width: 50vh !important;
    padding-top: 8px !important;
    margin-right: 5px !important;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10px;
    width: 100%;
    margin-top: 120px;
    align-self: flex-start !important;
  }

  .queue-active {
    font-size: 20px !important;
  }

  .doctor-name {
    font-size: 20px;
  }
  
  .poli-name {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .select {
    padding-right: 40px !important;
  }
}

@media (max-width: 600px) {
  .header-title {
    font-size: 27px;
  }
  .queue-bg {
    width: 100%;
    height: 100%;
    padding: 0px !important;
  }
  .table-content {
    min-width: 200px;
  }
  .select {
    width: 45vh !important;
    padding-right: 35px !important;
  }
}