.queue-bg {
    background: url("../../../../public/img/background/bg-tv.svg")
      linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
      padding: 20px;
      width: 100%;
      height: 100vh;
      font-family: 'Montserrat';
  }
  
.table{
  height: 72vh;
}


.mapped-table{
 color:black;
 font-weight: 700;
 font-size: 1vw;
}

@media (max-width: 800px) {
  .queue-bg {
    height: 100%;
  }
}

.words-bed {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  background-color: #011C70;
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  white-space: pre-line;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  height: auto;
  min-height: 7vh;
}

.words-splitter {
  width: 14vh;
  min-width: fit-content;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 700;
}

/* start:: for Bed Available (Ketersediaan Kamar) */
.bed-available-text-heading {
  font-size: 36px;
}

.bed-available-text-title-head {
  font-size: 32px;
}

.bed-available-text-title-side {
  background-color: black; 
  color: white;
  font-size: 30px;
}

.bed-available-text-value {
  background-color: white;
  color: black;
  font-weight: 700;
  font-size: 34px;
}

.bed-available-text-no-data {
  background-color: white;
  color: black;
  width: 100%;
  padding: 50px;
  font-size: 36px;
}
.height-col {
  height: 9vh;
}
/* end:: for Bed Available (Ketersediaan Kamar) */