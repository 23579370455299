.queue-bg {
    padding: 40px;
    width: 100%;
    min-height: 100vh;
    background: url("../../../../public/img/background/bg-tv.svg") no-repeat left
        bottom,
      linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
  }
  
  .queue-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .queue-word {
    height: 300px;
    font-size: 75px;
    color: mediumseagreen;
  }

  .box-down {
    padding: 0.75rem;
    margin: 0.5rem;
    background-color: #ffffff;
    border: 1px solid #c9c1d5;
    color: #000000;
    width: 100%;
    height: fit-content;
    font-size: 2vw;
  }
  
  .queue-card {
    min-height: 270px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .topbar-display {
    top: 20px;
    width: 100%;
  }