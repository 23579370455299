.queue-bg {
    background: url("../../../../public/img/background/bg-tv.svg")
      linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
      padding: 20px;
      width: 100%;
      height: 100vh;
      font-family: 'Montserrat';
  }
  
.antrian {
  font-size: 2vw;
  height: fit-content;
}

.top {
  height: 12vh;
}

.bot{
  height: 85vh;
}

.table{
  height: 72vh;
}

.words {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  background-color: #011C70;
  color: white;
  border-radius: 6px;
  white-space: pre-line;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  height: 10vh;
}

.title-table {
  background-color: black;
  color: white;
  margin: 20px 0 20px ;
  text-align: center;
  font-size: 1.5vw;
  padding: 5px;
  border-radius: 6px;
  font-weight: 600;
}

.mapped-table{
 color:black;
 font-weight: 700;
 font-size: 1vw;
}

@media (max-width: 800px) {
  .queue-bg {
    height: 100%;
  }
}