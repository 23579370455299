.header-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #ffff;
  position: relative;
  padding: 0 8px;
  width: 99%
}

.header-dashboard-content {
  position: absolute;
}