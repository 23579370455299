.pagination {
    display: flex;
    list-style-type: none;
    padding-left: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-item.disabled .page-link {
    cursor: not-allowed;
    color: #6c757d;
    pointer-events: none;
  }
  
  .page-item.active .page-link {
    background-color: #00bd84;
    border-color: #00bd84;
    color: white !important;
    cursor: default;
  }
  
  .page-link {
    cursor: pointer;
    padding: 6px 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: black !important;
    text-decoration: none;
  }
  
  .page-link:hover {
    background-color: #e9ecef;
    color: #0056b3;
  }
  
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: 4px;
  }
  
  .fa-caret-left,
  .fa-caret-right {
    font-size: 1rem;
  }
  