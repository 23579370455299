.partition {
  width: 1px;
  height: 95vh !important;
  background-color: rgba(170, 170, 170, 0.64);
  position: absolute;
  right: -20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 10px;
}

.button-create {
  border: none;
  background-color: transparent;
  padding: 5px 0px 0px 10px;
}

.button-delete {
  height: 40px;
  width: 100px;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #E02B1D !important;
  border-radius: 50px !important;
  background-color: #E02B1D !important;
  justify-content: "space-between";
  align-items:'center';
  margin: 5px 0px 0px 10px;
}

.policlinic-screen {
  width: 98%;
  height: 50px;
  padding: 10px 0px 0px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
}

.policlinic-screen-list {
  overflow-y: scroll !important;
  width: 100%;
  height: 80%;
  margin-top: 15px;
  position: relative;
}

.policlinic-screen-list::-webkit-scrollbar {
  display: none
}

.policlinic-screen-inactive {
  border: 1px solid #F5F5F5 !important;
}

.policlinic-screen-active {
  border: 2px solid #00BD84 !important;
}

.policlinic-screen-container {
  padding: 0px 0px 0px 10px;
  position: relative;
  height: 100%;
}

.policlinic-screen-container-detail {
  padding: 20px 10px 0px 10px;
}

.button-save {
  height: 40px;
  width: 100px;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 50px !important;
  background-color: #00bd84 !important;
}

.button-cancel {
  height: 40px;
  width: 100px;
  color: #00bd84 !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 50px !important;
  background-color: #ffff !important;
}

.button-cancel-modal {
  height: 40px;
  width: 100%;
  color: #00bd84 !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 50px !important;
  background-color: #ffff !important;
}

.error-message {
  margin-left: 16px;
  color: red
}

@media (max-width: 1500px) {
  .policlinic-screen-list-col {
    width: 400px !important;
  }
  
  .policlinic-screen-detail-col {
    width: 1000px !important;
  }
  .overflow-medium {
    overflow-y: hidden
  }
  .overflow-medium::-webkit-scrollbar {
    display: none
  }
  .policlinic-screen-list {
    height: 50vh;
  }
}