.partition {
  width: 1px;
  height: 95%;
  background-color: rgba(170, 170, 170, 0.64);
  right: -20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 10px;
}

.config-antrol-screen {
    width: 100%;
    height: 50px;
    padding: 0px 10px 0px 10px;
    margin-top: 10px;
    border-radius: 8px;
    display: "flex";
    justify-content: "space-between";
    align-items:'center';
    width: "100%";
  }

  .button-save {
    height: 100%;
    width: 80px;
    color: #ffffff !important;
    font-weight: bold !important;
    border: 1px solid #00bd84 !important;
    border-radius: 50px !important;
    background-color: #00bd84 !important;
  }
  
  .button-cancel {
    height: 100%;
    width: 80px;
    color: #00bd84 !important;
    font-weight: bold !important;
    border: 1px solid #00bd84 !important;
    border-radius: 50px !important;
    background-color: #ffff !important;
  }

  .input-select-control {
    height: 42px;
    color: #000000 !important;
    border: 1px solid #cccccc !important;
    border-radius: 50px !important;
    padding: 1px 10px 0px 10px !important;
    outline: none;
  }
  
  .config-antrol-screen-list {
    overflow-y: scroll !important;
    width: 100%;
    height: 80%;
    padding-right: 10px;
    margin-top: 15px;
  }

  .config-antrol-screen-list::-webkit-scrollbar {
    display: none
  }
  
  .config-antrol-screen-inactive {
    border: 1px solid #F5F5F5 !important;
  }
  
  .config-antrol-screen-active {
    border: 2px solid #00BD84 !important;
  }
  
  .config-antrol-screen-container {
    padding: 20px 0px 0px 10px;
  }

@media (max-width: 1500px) {
  .locket-list-col {
    width: 400px !important;
  }
  .locket-detail-col {
    width: 1000px !important;
  }
  .overflow-medium {
    overflow-y: hidden
  }
  .overflow-medium::-webkit-scrollbar {
    display: none
  }
  .config-antrol-screen-list {
    height: 50vh;
  }
}