.radio-button-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 60px;
}

.radio-button-container label {
  display: inline-flex;
  align-items: center;
}

.radio-button-container label span {
  padding-left: 15px;
}